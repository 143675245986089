import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

import Mission from "../components/Mission";
// import Testimonials from "../components/Testimonials";

const AboutPage = () => (
  <Layout>
    <SEO title="About Us" />
    <Mission/>
    {/* <Testimonials /> */}
  </Layout>
);

export default AboutPage;

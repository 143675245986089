import React from 'react';
// import SignUp from "./SignUp"

const Mission = props => {

  return (
    <section className="mission-statement">
      <div className="mission-statement-content">
        <div className="mission-statement-message">
          <h1>Our mission</h1>
          <p className="mission-statement-subtitle"> 
          STEMbase.org is a digital platform, developed for trainees by trainees, that amplifies and promotes available STEM opportunities and resources to aid readiness for STEM careers. Our mission is to facilitate access to information and serve as a one-stop site for trainees to navigate available opportunities and resources in STEM.
          </p> 
        </div>
      </div>
      <div className="vision-statement-content">
        <div className="vision-statement-message">
          <h1>Our vision</h1>
          <p className="vision-statement-subtitle"> 
          We aim to increase accessibility of resources and opportunities in STEM in order to engage, expose and prepare trainees for their future careers in STEM. We also aim to provide equal access to opportunities that aid personal and professional development. We have a strong focus on serving underrepresented and marginalized communities to bridge opportunity gaps and maximize retention and diverse representation in the STEM workforce.
          </p> 
        </div>
      </div>
      <div className="cta-statement-content">
        <div className="cta-statement-message">
          <h1>Looking to get involved?</h1>
          <p className="cta-statement-subtitle"> 
          As we are a community supported and trainee-led organization, we are always looking for contributors who can help us acheive our mission. Contact us at <a href = "mailto: info@stembase.org">info@stembase.org</a> and we'll send you information on opportunities to get involved.
          </p> 
          {/* <SignUp/> */}
        </div>
      </div>
    </section>
  )
};

export default Mission;
